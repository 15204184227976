// /************ Navigation fixe ***************/
// /********************************************/


//---- point à partir duquel on affiche la nav
var breakpoint = parseInt((window.innerHeight));


//---- position haut de l'élément
$.fn.bottomPos = function() {
	return this.offset().top + this.outerHeight();
};

//---- position basse de l'élément
$.fn.topPos = function() {
	return this.offset().top;
};

//---- position millieu
$.fn.middlePos = function() {
	return this.offset().top + (this.height()/2);
};

//---- ajout des classes current
function addCurrentClass(target){

	if ($(window).scrollTop() > $(target).topPos() && $(window).scrollTop() < $(target).bottomPos()) {
		$('a[href*="' + target + '"]').parent().addClass('current');
	}else{
		$('a[href*="' + target + '"]').parent().removeClass('current');
	}
	if($(window).scrollTop() + $(window).height() > ($(document).height() - 150)) {
		$('a[href*="#clients"]').parent().removeClass('current');
		$('a[href*="#contact"]').parent().addClass('current');
	}else {
		$('a[href*="#contact"]').parent().removeClass('current');
	}
}

//--- au scroll
window.onscroll = function() {
	if($(window).scrollTop() > breakpoint){
		$('.fixednav').addClass('active');

	}else{
		$('.fixednav').removeClass('active');
	}
	if($(window).scrollTop() > 40){
		$('.fixednav').addClass('scrolled');

	}else{
		$('.fixednav').removeClass('scrolled');
	}
	addCurrentClass('#mission');
	addCurrentClass('#expertises');
	addCurrentClass('#marches');
	addCurrentClass('#moyens');
	addCurrentClass('#methodes');
	addCurrentClass('#valeurs');
	addCurrentClass('#clients');
	addCurrentClass('#groupe');
};

//au click sur la langue
$( '.switch-lang-fr' ).on('click',function() {
	if($( '.switch-lang-en' ).hasClass('active')) {
		$( '.switch-lang-en' ).removeClass('active');
	}
	$( '.switch-lang-fr' ).addClass('active');
});

$( '.switch-lang-en' ).on('click',function() {
	if($( '.switch-lang-fr' ).hasClass('active')) {
		$( '.switch-lang-fr' ).removeClass('active')
	}
	$( '.switch-lang-en' ).addClass('active');
});

//--- responsive burger
$( '.fixednav-button' ).on('click',function(e) {
	e.preventDefault();
	if($(this).hasClass('active')){
		$(this).removeClass('active');
		$('.fixednav-button--burger').removeClass('active');
		$('.fixednav-ul').removeClass('active');
	}else{
		$(this).addClass('active');
		$('.fixednav-button--burger').addClass('active');
		$('.fixednav-ul').addClass('active');
	}
});

if(window.innerWidth < 768){
	$( '.fixednav-link, .fixednav-top' ).on('click',function(e) {
		e.preventDefault();
		if($('.fixednav-ul').hasClass('active')){
			$('.fixednav-ul, .fixednav-button, .fixednav-button--burger').removeClass('active');
		}
	});
}
