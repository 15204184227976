/****** Animation des 3 blocs de couleurs ***/
/********************************************/

$( '.marches-bloc--column' ).on('click',function(e) {
	if(!$(this).hasClass('active')){
		$( '.marches-bloc--column' ).removeClass('active');
		$( '.marches-bloc--column' ).removeClass('small');
		$(this).addClass('active');
		$(this).siblings().addClass('small');
	}
});

$( '.marches-bloc--close' ).on('click',function(e) {
	e.stopPropagation();
	if($(this).parent().hasClass('active')){
		$( '.marches-bloc--column' ).removeClass('active');
		$( '.marches-bloc--column' ).removeClass('small');
	}
});




