require('./contact');
require('../common/mentions-legales');
require('../common/smoothScroll');


//---- Initialisation de WOW (animation au scroll et gestion des des délais)
wow = new WOW();
wow.init();


//---- cookie warning
$( document ).ready(function() {
  //si le cookie warning est affiché
  if ( $('#cookies').length ) {
    $('body').addClass('cookieOn');
  }else{
    $('body').removeClass('cookieOn');
  }
  //au clic on cache le warning
  $('#cookies').on('click',function() {
    $(this).css('display', 'none');
    $('body').removeClass('cookieOn');
  });
});

//********** hauteur égales *************//
var highestBox = 0;
$('.equal-height').each(function(){ 
  if($(this).height() > highestBox){ 
    highestBox = $(this).height(); 
  }
});
$('.equal-height').outerHeight(highestBox);

//********* form animation ************//

function showApplyForm(){
  $('.content').toggleClass('form-open');
  if($('#candidature-form').length) {
  $('html, body').animate( { scrollTop: ($('#candidature-form').offset().top) }, 750); // Go
}
}

$('.recrutement-applybtn').on('click',function(e) {
  var $link = $(this);
  if($link.attr('href') === '#' || $link.attr('href') == ''){
    e.preventDefault();
    showApplyForm();
  }
});

if(window.location.href.indexOf("formAnchor") > -1) {
 showApplyForm();
}

$('.cookies-button').on('click',function(e) {
  e.preventDefault();
});


$("body .fancybox").fancybox({
  'transitionIn'  : 'elastic',
  'transitionOut' : 'elastic',
  'speedIn'   : 600,
  'speedOut'    : 200,
  'overlayShow' : true,
  helpers: {
    title : {
      type : 'float'
    }
  }
});

(function () {
  var arr_data_href = document.querySelectorAll('[data-href]');

  for (i = 0, len = arr_data_href.length; i < len; i++) {
    arr_data_href[i].addEventListener("click", function (_e) {
      var attr_data_href = this.getAttribute('data-href');
      window.open(attr_data_href, "_blank");
    });
  }
})();

if($('.ressource-details').length) {
  $('.recrutement-applyform').hide();
}

if ($('.cms_forms_msg').length) {
  $('.ressource-details-btn').show();
}else {
  $('.ressource-details-btn').hide();
};

/*fancybox yt video*/
$(".fancybox-video").on('click',function() {
  $.fancybox({
    'padding'       : 0,
    'autoScale'     : false,
    'transitionIn'  : 'none',
    'transitionOut' : 'none',
    'title'         : this.title,
    'width'     : 680,
    'height'        : 495,
    'href'          : this.href.replace(new RegExp("watch\\?v=", "i"), 'embed/'),
    'type'          : 'iframe',
    'swf'           : {
      'wmode'        : 'transparent',
      'allowfullscreen'   : 'true'
    }
  });
  return false;
});

function hauteurEgale(group) {
  tallest = 0;
  group.each(function() {
    thisHeight = $(this).height();
    if(thisHeight > tallest) {
      tallest = thisHeight;   
    }
  });
  group.css('height',tallest+'px');
}

$(window).on('load',function () {
  if (window.innerWidth > 480) {
    hauteurEgale($(".same-height"));
  };
})