/************ POP-UP MENTIONS LEGALES ***************/
/****************************************************/
'use scrict';

//---- au clic du lien
$( '.mentions-link, .terms' ).on('click',function(e) {
	e.preventDefault();
	if($('.mentions').hasClass('active')){
		$('.mentions').removeClass('active');
		$('.overlay, html').removeClass('mentionsOn');
	}else{
		$('.mentions').addClass('active');
		$('.overlay, html').addClass('mentionsOn');
	}
});

//---- au clic du btn fermer
$( '.mentions-close' ).on('click',function(e) {
	e.preventDefault();
	$('.mentions').removeClass('active');
	$('.overlay, html').removeClass('mentionsOn');
});

//---- au clic en dehors de la div
$(document).on('mouseup',function (e) {
	var container = $('.mentions');
	if (!container.is(e.target) && container.has(e.target).length === 0){
		container.removeClass('active');
		$('.overlay').removeClass('mentionsOn');
	}
});


