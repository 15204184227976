require('./fixed-navigation');
require('./companies');
require('./contact');
require('../common/mentions-legales');
require('../common/clients');
require('../common/smoothScroll');

// Modal Homepage
$(window).on('load',function(){
	if ($('#myModal').length > 0) {
		$('#myModal').modal('show');
	}
});

//---- Initialisation de WOW (animation au scroll et gestion des des délais)
wow = new WOW();
wow.init();

//---- header qui prends la hauteur du viewport
function resizeHeader(){
	document.querySelectorAll('.header')[0].style.minHeight = window.innerHeight + 'px';
	document.querySelectorAll('.header')[0].style.lineHeight = window.innerHeight + 'px';
}
  resizeHeader();
$( window ).on('resize',function() {
  resizeHeader();
});






//---- cookie warning
$( document ).ready(function() {
  //si le cookie warning est affiché
  if ( $('#cookies').length ) {
    $('body').addClass('cookieOn');
  }else{
    $('body').removeClass('cookieOn');
  }
  //au clic on cache le warning
  $('#cookies').on('click',function() {
    $(this).css('display', 'none');
    $('body').removeClass('cookieOn');
  });
});