//---- hauteur égales pour strate de contact
function equalHeightContact(group) {
	if(window.innerWidth > 992){
	tallest = 0;
	group.each(function() {

		thisHeight = $(this).outerHeight();
		if(thisHeight > tallest) {
			tallest = thisHeight;
		}
	});
	$('.contact-row').height(tallest);
}else{
		$('.contact-row').height('auto');
	}
}

window.onresize = function() {
	equalHeightContact($('.equal-height'));
};

$( document ).ready(function() {
	equalHeightContact($('.equal-height'));
});




if(window.location.href.indexOf("formAnchor") > -1) {
       
}
