/****** Animation caroussel de clients ***/
/********************************************/
'use strict';

var panelImg = setNumberofImg();
var animation = false;
var countImg = parseInt($('.clients-caroussel-img').length);
var currentImg = 1;
var view = $('.clients-caroussel-view');
var items = $('.clients-caroussel-items');
var imgWidth = view.width() / panelImg;


//---- initialise la taille des images
function init(){
  $('.clients-caroussel-imgwrapper').css('width', (imgWidth) + 'px');
  panelImg = setNumberofImg();
}


//---- retourne le nombre d'images en fonction du device
function setNumberofImg() {
  var output = null;
  if($( window ).width() > 992){
    output = 5;
  }
  if($( window ).width() < 992 && $( window ).width() > 768){
    output = 2;
  }
  if($( window ).width() < 768){
    output = 1;
  }
  return output;
}

//---- retourne l'entier d'une valeur donnée
function getInt(el){
  var int = el.replace(/[^-\d\.]/g, '');
  return Math.round(int);
}

//---- au click de next déplacer le container de toutes les images
function next () {
 if ((countImg > panelImg)){
  animation = true;
  if(currentImg <= (countImg - panelImg)){
    var width = getInt(items.css('left')) - (imgWidth);
    items.css('left', (width) + 'px');
    currentImg++;
  }else{
    items.css('left', '0');
    currentImg = 1;
  }
}
}

//---- au click de next déplacer le container de toutes les images
function prev () {
  if ((countImg > panelImg)){
    animation = true;
    if(currentImg > 1){
      var width = getInt(items.css('left')) + imgWidth;
      items.css('left', (width) + 'px');
      currentImg--;
    } else{
      items.css('left', '0');
      currentImg = 1;
    }
  }
 
}


//---- autoplay
if(countImg > panelImg){
  var autoplay = autoplay();
}
function autoplay(){
  return setInterval(function(){ next(); }, 3000);
}

//---- ajouter taille des images au resize
init();
$( window ).on('resize',function() {
  init();
});

//---- evenements au click

$( '.clients-caroussel-next' ).on('click',function() {
  next();
  window.clearInterval(autoplay);
});

$( '.clients-caroussel-prev' ).on('click',function() {
  prev();
  window.clearInterval(autoplay);
});




