// /************ Smooth scroll des ancres ***************/
// /*****************************************************/

$('.scroll').on('click', function() { // Au clic sur un élément .scroll
      var page = $(this).attr('href'); // ancre cible
      page = '#'+page.replace(/^[^#]*#([\s\S]*)$/, '$1'); //suppression du slash
      var speed = 750; // Durée de l'animation (en ms)
      $('html, body').animate( { scrollTop: ($(page).offset().top +2) }, speed); // Go
      return false;
});
